<template>
  <div class="d-inline-flex" v-if="providerName != null && providerObj != null">
    <v-tooltip top z-index="999" nudge-top="-4px">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-chip
            class="equipment-provider px-2"
            :small="!small"
            :x-small="small"
            :color="noColor ? 'white' : providerObj.color"
            :text-color="noColor ? 'grey darken-2' : providerObj.textColor"
          >
            <span class="font-weight-medium" style="line-height: 0">{{
              providerObj.text.toUpperCase()
            }}</span>
          </v-chip>
        </div>
      </template>
      <span>{{ providerObj.description }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Enums from "../../../../plugins/enums";

export default {
  name: "equipment-provider",
  data() {
    return {
      providerObj: null,
    };
  },
  props: {
    providerName: {
      type: String,
      default: null,
    },
    noColor: {
      type: Boolean,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateProviderObj();
  },
  computed: {},
  methods: {
    updateProviderObj() {
      if (this.providerName != null) {
        this.providerObj = this.getEnumMember(Enums.PROVIDERS, this.providerName);
      }
    },
  },
  watch: {
    providerName: {
      handler() {
        this.updateProviderObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
