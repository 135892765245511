import Api from "../../../Shared/services/api";
import { buildParams } from "../../../../plugins/helpers";

const baseUrl = "ExternalEquipments";
export default {
  get() {
    return Api().get(`${baseUrl}`);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
};
